import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import s from './ContinueBrowsing.scss';
import settingsParams from '../../../settingsParams';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum ContinueBrowsingDataHook {
  root = 'ContinueBrowsingDataHook.root',
  link = 'ContinueBrowsingDataHook.link',
}

const handleClick = (e: React.MouseEvent, callback: Function) => {
  e.preventDefault();
  callback();
};

export function ContinueBrowsing() {
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {continueLinkHref, onContinueLinkClick} = useControllerProps().navigationStore;

  return (
    <div data-hook={ContinueBrowsingDataHook.root} className={s.root}>
      <a
        href={continueLinkHref}
        className={s.link}
        onClick={(e) => handleClick(e, onContinueLinkClick)}
        data-hook={ContinueBrowsingDataHook.link}>
        {getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_CAPTION)}
      </a>
    </div>
  );
}
