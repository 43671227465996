import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';
import settingsParams from '../../../settingsParams';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum PaymentMethodSectionDataHook {
  root = 'PaymentMethodSectionDataHook.root',
  title = 'PaymentMethodSectionDataHook.title',
  content = 'PaymentMethodSectionDataHook.content',
}

export const PaymentMethodsColumn = () => {
  const {t} = useTranslation();
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const localeKeys = useLocaleKeys();
  const {paymentMethodNames} = useControllerProps().thankYouPageStore;

  const paymentMethods = paymentMethodNames
    .filter((name) => name !== 'N/A')
    .map((name) => (name === 'thankYouPage.giftCard.label' ? t(name) : name));
  if (!paymentMethods.length) {
    return null;
  }

  const content =
    paymentMethods.length > 1
      ? localeKeys.thankYouPage.paymentMethod.multipleMethods.label({
          paymentMethod1: paymentMethods[0],
          paymentMethod2: paymentMethods[1],
        })
      : paymentMethods[0];

  return (
    <div data-hook={PaymentMethodSectionDataHook.root}>
      <ShippingSectionText
        dataHook={PaymentMethodSectionDataHook.title}
        text={getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_PAYMENT_METHOD_LABEL)}
        isTitle={true}
      />
      <ShippingSectionText dataHook={PaymentMethodSectionDataHook.content} text={content} />
    </div>
  );
};
