import * as React from 'react';
import s from '../../ProductLineItemBase.scss';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import {classes, st} from './ReadOnlyQuantity.st.css';

export interface ReadOnlyQuantityProps {
  quantity: number;
  message: string;
  className?: string;
}

export const ReadOnlyQuantity = ({quantity, message, className}: ReadOnlyQuantityProps) => (
  <div className={st(classes.root, className, s.readOnlyQty)} data-hook={ProductLineItemDataHook.ReadOnlyQuantity}>
    {`${message} ${quantity}`}
  </div>
);

ReadOnlyQuantity.displayName = 'ProductLineItem.ReadOnlyQuantity';
