import * as React from 'react';
import {classes, st} from './SubtotalRow.st.css';
import {Text} from 'wix-ui-tpa';
import {TotalsDataHook} from '../../Totals';

export interface SubtotalRowProps {
  title: string;
  value: string;
  className?: string;
  dataHook?: string;
}

export const SubtotalRow = ({title, value, className, dataHook}: SubtotalRowProps) => {
  return (
    <tr
      data-hook={dataHook ?? /* istanbul ignore next */ TotalsDataHook.SubtotalsRow}
      className={st(classes.root, className)}>
      <td className={classes.labelTextAlign}>
        <Text className={classes.subTotalTitleText} data-hook={TotalsDataHook.SubtotalsRowTitle}>
          {title}
        </Text>
      </td>
      <td className={classes.textAlign}>
        <Text className={classes.subTotalTitleValue} data-hook={TotalsDataHook.SubtotalsRowValue}>
          {value}
        </Text>
      </td>
    </tr>
  );
};

SubtotalRow.displayName = 'Totals.SubtotalRow';
