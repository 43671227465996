import React from 'react';
import s from './Header.scss';
import {useControllerProps} from '../../ControllerContext';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import {useStyles} from '@wix/tpa-settings/react';
import {disableTranslationEscaping} from '../../../disableTranslationEscaping';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum HeaderDataHook {
  root = 'HeaderDataHook.root',
  title = 'HeaderDataHook.title',
  subtitle = 'HeaderDataHook.subtitle',
  orderNumberLine = 'HeaderDataHook.orderNumberLine',
}

export function Header() {
  const localeKeys = useLocaleKeys();
  const styleSettings = useStyles();
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {buyerName, isSubscription, orderId, hideOrderNumber} = useControllerProps().thankYouPageStore;

  const getSubscriptionTitle = () => {
    return (
      getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_TITLE) ??
      localeKeys.THANK_YOU_PAGE_SUBSCRIPTION_TITLE(disableTranslationEscaping({fullName: buyerName}))
    );
  };

  const getOrderTitle = () => {
    const customTitle = getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_TITLE);
    if (customTitle) {
      return customTitle;
    }

    return buyerName
      ? localeKeys.thankYouPage.title.withName(disableTranslationEscaping({name: buyerName}))
      : localeKeys.thankYouPage.title.withoutName();
  };

  const getSubTitle = () => {
    const customSubTitle = isSubscription
      ? getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_SUBTITLE)
      : getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_SUBTITLE);
    if (customSubTitle) {
      return customSubTitle;
    }
    return localeKeys.thankYouPage.subtitle.orderConfirmation();
  };

  const getOrderNumberTitle = () => {
    const customTitle = getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_ORDER_NUMBER_LABEL);
    if (customTitle) {
      return customTitle;
    }

    return localeKeys.thankYouPage.subtitle.orderNumber();
  };

  return (
    <div data-hook={HeaderDataHook.root} className={s.root}>
      <h1 className={s.title}>
        <span data-hook={HeaderDataHook.title}>{isSubscription ? getSubscriptionTitle() : getOrderTitle()}</span>
      </h1>

      <div className={s.subtitle} data-hook={HeaderDataHook.subtitle}>
        <span>{getSubTitle()}</span>
      </div>

      {!hideOrderNumber && !isSubscription && styleSettings.get(stylesParams.THANK_YOU_PAGE_ORDER_NUMBER_SELECTION) && (
        <div className={s.orderNumberLine} data-hook={HeaderDataHook.orderNumberLine}>
          <span className={s.orderNumberTitle}>{getOrderNumberTitle()}</span>
          <span className={s.orderNumberValue}>{orderId}</span>
        </div>
      )}
    </div>
  );
}
