import React from 'react';
import {useControllerProps} from '../../../ControllerContext';
import {Text, Divider} from 'wix-ui-tpa';
import * as s from '../TotalsSection.scss';
import {TotalsSectionDataHook} from '../TotalsSection';
import {classes} from './BuyerNoteSectionOverride.st.css';
import {useLocaleKeys} from '../../../../LocaleKeysProvider';
import classNames from 'classnames';

export const BuyerNoteSection = () => {
  const {buyerNote} = useControllerProps().thankYouPageStore;
  const localeKeys = useLocaleKeys();

  return (
    <div className={classNames(s.section, s.buyerNote)} data-hook={TotalsSectionDataHook.buyerNoteWrapper}>
      {buyerNote && (
        <>
          <div>
            <Text className={classes.noteTitle} data-hook={TotalsSectionDataHook.buyerNoteLabel}>
              {localeKeys.thankYouPage.customerNote.label()}
            </Text>
          </div>
          <div>
            <Text className={classes.noteText} data-hook={TotalsSectionDataHook.buyerNote}>
              {buyerNote}
            </Text>
          </div>
          <Divider className={s.buyerNoteDivider} />
        </>
      )}
    </div>
  );
};
