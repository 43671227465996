// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';
import {SubscriptionFrequency} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';
import {SubscriptionFrequency as SubscriptionFrequencyType, SubscriptionInfo} from '../../../../../types/app.types';
import {LocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export const getSubscriptionDuration = ({
  subscription,
  localeKeys,
}: {
  subscription: SubscriptionInfo;
  localeKeys: LocaleKeys;
}) => {
  if (subscription.subscriptionSettings.autoRenewal) {
    return localeKeys.thankYouPage.pricingPlans.duration.label({
      number: undefined,
      frequency: localeKeys.THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW(),
    });
  }

  const duration = subscription.subscriptionSettings?.billingCycles * subscription.subscriptionSettings?.interval;
  const frequency = frequencyText({
    duration,
    frequency: subscription.subscriptionSettings?.frequency,
    localeKeys,
  });

  return localeKeys.thankYouPage.pricingPlans.duration.label({
    number: duration > 0 ? duration : undefined,
    frequency,
  });
};

export const getSubscriptionDurationOld = ({
  t,
  subscriptionDuration,
  subscriptionFrequency,
}: {
  t: any;
  subscriptionDuration: number;
  subscriptionFrequency: SubscriptionFrequency;
}) => {
  if (!subscriptionDuration) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW');
  }

  const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency, subscriptionDuration));
  if (subscriptionDuration === 1) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
      frequencyUnitSingular: frequencyUnit,
    });
  }

  return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
    numberOfFrequencyUnits: subscriptionDuration,
    frequencyUnitSingular: frequencyUnit,
  });
};

export const getSubscriptionDetailsTranslationKey = (interval: number, frequency: SubscriptionFrequency) => {
  if (interval === 1) {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_week_singular_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_month_singular_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_year_singular_label';
    }
  } else if (interval === 2) {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_2_weeks_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_2_months_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_2_years_label';
    }
  } else {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_weeks_plural_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_months_plural_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_years_plural_label';
    }
  }
};

export function frequencyText({
  duration,
  frequency,
  localeKeys,
}: {
  duration: number;
  frequency: SubscriptionFrequencyType;
  localeKeys: LocaleKeys;
}) {
  switch (frequency) {
    case SubscriptionFrequencyType.DAY: {
      return duration > 1
        ? localeKeys.thankYouPage.pricingPlans.frequency.days.plural()
        : localeKeys.thankYouPage.pricingPlans.frequency.day.singular();
    }
    case SubscriptionFrequencyType.WEEK: {
      return duration > 1
        ? localeKeys.thankYouPage.pricingPlans.frequency.weeks.plural()
        : localeKeys.thankYouPage.pricingPlans.frequency.week.singular();
    }
    case SubscriptionFrequencyType.MONTH: {
      return duration > 1
        ? localeKeys.thankYouPage.pricingPlans.frequency.months.plural()
        : localeKeys.thankYouPage.pricingPlans.frequency.month.singular();
    }
    case SubscriptionFrequencyType.YEAR: {
      return duration > 1
        ? localeKeys.thankYouPage.pricingPlans.frequency.years.plural()
        : localeKeys.thankYouPage.pricingPlans.frequency.year.singular();
    }
    case SubscriptionFrequencyType.UNDEFINED: {
      return localeKeys.thankYouPage.pricingPlans.duration.untilCanceled();
    }
  }
}
