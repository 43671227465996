import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';
import settingsParams from '../../../settingsParams';
import {getFormattedAddressTranslation} from '../../../addressUtils';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum BillingSectionDataHook {
  root = 'BillingSectionDataHook.root',
  title = 'BillingSectionDataHook.title',
  fullName = 'BillingSectionDataHook.fullName',
  address = 'BillingSectionDataHook.address',
  phone = 'BillingSectionDataHook.phone',
  company = 'BillingSectionDataHook.company',
}

export const BillingColumn = () => {
  const localeKeys = useLocaleKeys();
  const {billingAddress, isEditor, isPreview} = useControllerProps().thankYouPageStore;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {addressLine1, addressLine2, city, country, subdivision, zipCode, fullName, phoneNumber, company} =
    billingAddress;

  const getBillingColumnTitle = () => {
    return getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_BILLING_ADDRESS_LABEL);
  };

  const addressData =
    isEditor || isPreview
      ? addressLine2
      : getFormattedAddressTranslation({addressLine1, addressLine2, country, city, subdivision, zipCode}, localeKeys);

  return (
    <div data-hook={BillingSectionDataHook.root}>
      <ShippingSectionText dataHook={BillingSectionDataHook.title} text={getBillingColumnTitle()} isTitle={true} />
      <ShippingSectionText dataHook={BillingSectionDataHook.fullName} text={fullName} />
      <ShippingSectionText dataHook={BillingSectionDataHook.address} text={addressData} />
      <ShippingSectionText dataHook={BillingSectionDataHook.phone} text={phoneNumber} />
      <ShippingSectionText dataHook={BillingSectionDataHook.company} text={company} />
    </div>
  );
};
