import React, {Children, ReactElement} from 'react';
import {SubtotalRow} from './Partials/SubtotalRow/SubtotalRow';
import s from './Totals.scss';
import {TotalRow} from './Partials/TotalRow/TotalRow';

export enum TotalsDataHook {
  TotalsTable = 'totals-table',
  TotalsRow = 'total-row',
  TotalsRowTitle = 'total-row-title',
  TotalsRowValue = 'total-row-value',
  TotalsRowSecondaryValue = 'total-row-secondary-value',
  SubtotalsRow = 'subtotal-row',
  SubtotalsRowTitle = 'subtotal-row-title',
  SubtotalsRowValue = 'subtotal-row-value',
  SummaryDivider = 'summery-divider',
}

export interface TotalsProps {
  children?: (ReactElement | false)[] | ReactElement | false;
  dataHook?: string;
}

export const Totals = ({children, dataHook}: TotalsProps) => {
  const flattenedChildren: ReactElement[] = (Children.toArray(children) as ReactElement[]).reduce(
    (accumulator: ReactElement[], currentValue: ReactElement) => accumulator.concat(currentValue),
    []
  );

  const sections = flattenedChildren.filter(
    (child) =>
      (child?.type && (child.type as React.ComponentType<any>).displayName === SubtotalRow.displayName) ||
      (child?.type && (child.type as React.ComponentType<any>).displayName === TotalRow.displayName)
  );

  return (
    <div data-hook={dataHook ?? /* istanbul ignore next */ TotalsDataHook.TotalsTable} className={s.totalsWrapper}>
      <table className={s.tableWrapper}>
        <tbody>{sections}</tbody>
      </table>
    </div>
  );
};

Totals.displayName = 'Totals';
Totals.SubtotalRow = SubtotalRow;
Totals.TotalRow = TotalRow;
