import * as React from 'react';
import {classes, st} from './Price.st.css';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';

export interface PriceProps {
  formattedPrice?: string;
  className?: string;
}

export const Price = ({formattedPrice, className}: PriceProps) => (
  <div className={st(classes.root, className)}>
    {formattedPrice && (
      <span data-hook={ProductLineItemDataHook.Price} className={classes.prices}>
        {formattedPrice}
      </span>
    )}
  </div>
);

Price.displayName = 'ProductLineItem.Price';
