import * as React from 'react';
import {classes, st} from './PaymentTypeLabel.st.css';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';

export interface PaymentTypeLabelProps {
  label: string;
}

export const PaymentTypeLabel = ({label}: PaymentTypeLabelProps) => (
  <div className={st(classes.root)} data-hook={ProductLineItemDataHook.PaymentTypeLabel}>
    {label}
  </div>
);

PaymentTypeLabel.displayName = 'ProductLineItem.PaymentTypeLabel';
