import React from 'react';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import * as _ from 'lodash';
import {classes, st} from './Options.st.css';

export interface OptionsProps {
  itemId: number | string;
  options: {title: string; value: string}[];
  className?: string;
  maxOptionValLength?: number;
}

export const Options = ({itemId, options, className, maxOptionValLength}: OptionsProps) => {
  const getValue = (value: string) => {
    const maxValueLength = maxOptionValLength;
    if (value?.length <= maxValueLength) {
      return value;
    }
    return _.truncate(value, {length: maxValueLength});
  };

  const renderOption = (option: {title: string; value: string}) => {
    const {title, value} = option;
    const trimmedOptionTitle = title?.trim();
    const trimmedOptionValue = value?.trim();

    if (!(trimmedOptionTitle && trimmedOptionValue)) {
      return <li key={`option-item-${itemId}-value-${title ?? value}`}>{getValue(title || value)}</li>;
    }
    return (
      <li key={`option-item-${itemId}-value-${title}`}>
        {title}: {getValue(value)}
      </li>
    );
  };

  return (
    <ul className={st(classes.root, className)} data-hook={ProductLineItemDataHook.Options}>
      {options?.map(renderOption)}
    </ul>
  );
};

Options.displayName = 'ProductLineItem.Options';
