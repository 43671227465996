import * as React from 'react';
import classNames from 'classnames';
import s from './ProductLineItemBase.scss';
import {Name} from './partials/Name/Name';
import {TotalPrice} from './partials/TotalPrice/TotalPrice';
import {ProductLineItemThumbnail} from '@wix/wixstores-client-common-components/dist/es/src/ProductLineItem/ProductLineItemThumbnail/ProductLineItemThumbnail';
import {ReadOnlyQuantity} from './partials/ReadOnlyQuantity/ReadOnlyQuantity';
import {Subscription} from './partials/Subscription/Subscription';
import {Link} from './partials/Link/Link';
import {Price} from './partials/Price/Price';
import {PaymentTypeLabel} from './partials/PaymentTypeLabel/PaymentTypeLabel';
import {Options} from './partials/Options/Options';

export enum ProductLineItemDataHook {
  'Name' = 'ProductLineItemDataHook.name',
  'Options' = 'ProductLineItemDataHook.options',
  'Price' = 'ProductLineItemDataHook.price',
  'ComparePrice' = 'ProductLineItemDataHook.comparePrice',
  'TotalPrice' = 'ProductLineItemDataHook.totalPrice',
  'TotalPriceFrequency' = 'ProductLineItemDataHook.totalPriceFrequency',
  'Remove' = 'ProductLineItemDataHook.remove',
  'Quantity' = 'ProductLineItemDataHook.quantity',
  'ReadOnlyQuantity' = 'ProductLineItemDataHook.readOnlyQuantity',
  'Subscription' = 'ProductLineItemDataHook.Subscription',
  'LinkText' = 'ProductLineItemDataHook.LinkText',
  'Link' = 'ProductLineItemDataHook.Link',
  'PaymentTypeLabel' = 'ProductLineItemDataHook.PaymentTypeLabel',
}

export interface ProductLineItemsProps {
  children?: React.ReactNode;
  withDivider: boolean;
  experiments: {
    responsiveTYPCss?: boolean;
  };
}

interface ProductLineItemsChildren {
  Image: typeof ProductLineItemThumbnail;
  Name: typeof Name;
  Price: typeof Price;
  TotalPrice: typeof TotalPrice;
  Options: typeof Options;
  ReadOnlyQuantity: typeof ReadOnlyQuantity;
  Subscription: typeof Subscription;
  Link: typeof Link;
  PaymentTypeLabel: typeof PaymentTypeLabel;
}

export const ProductLineItemBase: React.FC<ProductLineItemsProps> & ProductLineItemsChildren = (
  props: ProductLineItemsProps
) => {
  const {children, withDivider, experiments} = props;
  const {responsiveTYPCss} = experiments;

  const NameToCmpMap = (React.Children.toArray(children) as unknown as JSX.Element[]).reduce<{
    [key: string]: JSX.Element;
  }>((acc, child) => {
    acc[child.type.displayName] = child;
    return acc;
  }, {});

  const image = NameToCmpMap['ProductLineItem.Image'];
  const name = NameToCmpMap['ProductLineItem.Name'];
  const price = NameToCmpMap['ProductLineItem.Price'];
  const totalPrice = NameToCmpMap['ProductLineItem.TotalPrice'];
  const subscription = NameToCmpMap['ProductLineItem.Subscription'];
  const options = NameToCmpMap['ProductLineItem.Options'];
  const remove = NameToCmpMap['ProductLineItem.Remove'];
  const outOfStock = NameToCmpMap['ProductLineItem.OutOfStock'];
  const quantity = NameToCmpMap['ProductLineItem.Quantity'];
  const readOnlyQuantity = NameToCmpMap['ProductLineItem.ReadOnlyQuantity'];
  const link = NameToCmpMap['ProductLineItem.Link'];
  const paymentTypeLabel = NameToCmpMap['ProductLineItem.PaymentTypeLabel'];

  return (
    <div className={classNames(s.itemWrapper, withDivider ? s.withDivider : null)}>
      {responsiveTYPCss ? <div className={s.thumbnail}>{image}</div> : null}
      <div className={s.container}>
        {responsiveTYPCss ? null : <div className={s.containerItem}>{image}</div>}
        <div className={classNames(s.containerItem, s.detailsContainer)}>
          <div className={s.details}>
            {name}
            {paymentTypeLabel}
            {price}
            {subscription}
            {options}
            {link}
          </div>
        </div>
        <div className={classNames(s.containerItem, s.quantityContainer)}>
          {quantity}
          {readOnlyQuantity}
        </div>
        <div className={classNames(s.containerItem, s.totalContainer)}>{totalPrice}</div>
        <div
          className={classNames(
            s.containerItem,
            s.removeButtonContainer,
            responsiveTYPCss && !remove ? s.hiddenElement : null
          )}>
          {remove}
        </div>
      </div>
      {responsiveTYPCss ? <div className={s.outOfStockContainer}>{outOfStock}</div> : outOfStock}
    </div>
  );
};

ProductLineItemBase.displayName = 'ProductLineItemBase';

ProductLineItemBase.Image = ProductLineItemThumbnail;
ProductLineItemBase.Name = Name;
ProductLineItemBase.Price = Price;
ProductLineItemBase.TotalPrice = TotalPrice;
ProductLineItemBase.Options = Options;
ProductLineItemBase.ReadOnlyQuantity = ReadOnlyQuantity;
ProductLineItemBase.Subscription = Subscription;
ProductLineItemBase.Link = Link;
ProductLineItemBase.PaymentTypeLabel = PaymentTypeLabel;
