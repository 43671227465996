import * as React from 'react';
import s from '../../ProductLineItemBase.scss';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import {classes, st} from './TotalPrice.st.css';
import {useControllerProps} from '../../../../ControllerContext';
import {getSubscriptionDetailsTranslationKey} from '../../../ProductLineItemsSection/utils';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {SubscriptionFrequency} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';
import {Text} from 'wix-ui-tpa/cssVars';

export interface TotalPriceProps {
  formattedTotalPrice?: string;
  className?: string;
  frequency?: SubscriptionFrequency;
  interval?: number;
  billingCycles?: number;
}

export const TotalPrice = ({formattedTotalPrice, className, frequency, interval, billingCycles}: TotalPriceProps) => {
  const {t} = useTranslation();
  const {useNewSubscriptionView} = useControllerProps().thankYouPageStore;
  const shouldShowFrequency = useNewSubscriptionView && billingCycles && billingCycles !== 1;
  const frequencyTranslationKey = getSubscriptionDetailsTranslationKey(interval, frequency);

  return (
    <div className={st(classes.root, className, s.totalPrice)}>
      {formattedTotalPrice && (
        <div data-hook={ProductLineItemDataHook.TotalPrice} className={s.totalPrice}>
          {formattedTotalPrice}
        </div>
      )}
      {shouldShowFrequency && (
        <Text className={classes.frequency} data-hook={ProductLineItemDataHook.TotalPriceFrequency}>
          {t(frequencyTranslationKey, {
            numberOfFrequencyUnits: interval,
          })}
        </Text>
      )}
    </div>
  );
};

TotalPrice.displayName = 'ProductLineItem.TotalPrice';
