import * as React from 'react';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import {classes, st} from './Name.st.css';

export interface NameProps {
  name: string;
  className?: string;
}

export const Name = ({name, className}: NameProps) => (
  <div data-hook={ProductLineItemDataHook.Name} className={st(classes.root, className)}>
    {name}
  </div>
);

Name.displayName = 'ProductLineItem.Name';
