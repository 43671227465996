import * as React from 'react';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import {classes, st} from './Link.st.css';

export interface LinkProps {
  title: string;
  icon: React.ReactElement;
  onClick?: () => void;
  className?: string;
  href: string;
}

export const Link = ({title, icon, onClick, href, className}: LinkProps) => (
  <a
    className={st(classes.root, className)}
    href={href}
    onClick={onClick}
    target="_blank"
    download
    rel="noreferrer"
    data-hook={ProductLineItemDataHook.Link}>
    {icon}
    <span data-hook={ProductLineItemDataHook.LinkText}>{title}</span>
  </a>
);

Link.displayName = 'ProductLineItem.Link';
