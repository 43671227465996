import React from 'react';
import s from './ThankYouPageApp.scss';
import {useControllerProps} from '../ControllerContext';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {THANK_YOU_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {PageContent} from './PageContent';
import classNames from 'classnames';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export enum ThankYouPageAppDataHook {
  root = 'ThankYouPageAppDataHook.root',
  rootContent = 'ThankYouPageAppDataHook.rootContent',
  blank = 'ThankYouPageAppDataHook.blank',
}

function ThankYouPageApp() {
  const {ssrError, skipRender, thankYouPageStore} = useControllerProps();
  const {isMobile} = useEnvironment();

  /* istanbul ignore if */
  if (ssrError || skipRender) {
    return <div data-hook={ThankYouPageAppDataHook.blank} />;
  }

  const className = classNames(
    s.root,
    thankYouPageStore.responsiveTYPCss ? 'responsive-typ' : 'non-responsive-typ',
    isMobile ? 'mobile-mode' : undefined
  );

  const MainContent = () => {
    return (
      <div data-hook={ThankYouPageAppDataHook.rootContent} className={s.limitContent}>
        <PageContent />
      </div>
    );
  };

  return (
    <main data-hook={ThankYouPageAppDataHook.root} className={className}>
      <MainContent />
    </main>
  );
}

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(ThankYouPageApp, {
  dsn: THANK_YOU_PAGE_DSN,
  config: {environment: 'Native Component'},
});
